import React from 'react';

const ServiceCard = ({ serviceTitle, children, services }) => {
  return (
    <div className='shadow-md border rounded-lg py-16 px-4 xl:px-24 flex flex-col items-center text-lg'>
      <h1 className='font-bold md:text-3xl mb-6 text-2xl'>{serviceTitle}</h1>
      {children && (
        <p className='my-4 text-center w-3/4 xl:w-full'>{children}</p>
      )}
      <ul className='px-10 md:px-16 xl:px-0 py-4 w-11/12 md:w-8/12 xl:w-10/12'>
        {services.map((service, index) => (
          <li key={index} className='list-disc mb-3'>
            {service}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ServiceCard;
